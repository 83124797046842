import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import './styles/PDFFields.css';
import PDFSelectedList from './PDFSelectedList';
import {
    Box,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Checkbox,
    Tooltip,
    IconButton,
    Button,
    Switch,
    debounce,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Delete } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    DragDropContext,
    Draggable,
    DraggingStyle,
    Droppable,
    DropResult,
    NotDraggingStyle
} from "react-beautiful-dnd";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckedIcon from './checkedIcon';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import BasicFieldIcons from './BasicFieldIcons';
import Divider from '@mui/material/Divider';

const basicFields = ['Name', 'Company', 'Signature', 'Date', 'Radio', 'Initial', 'Text', 'Checkbox', 'Number', 'Title', 'Email', 'Dropdown'];
const circle_active_color = ["#377DFF", "#00C9A7", "#F5CA99", "#FFC931", "#ED4C78", "#71869D", "#B867DA", "#E8D20D", "#775A8C", "#8A8A5C", "#F86982", "#2EE0AF", "#76B13A", "#3F6CA6", "#0C797D"];
const bgcolor = ["#377dff1a", "#00c9a71a", "#f5ca991a", "#FFE0D1", "#ed4c781a", "#1321441a", "#F7E2FF", "#FFFDCE", "#CEC1D7", "#D7D7C1", "#ffbcc8cf", "#E0FFF7", "#B8E986", "#DAE4F1", "#97D6D9"];

const signer = [{ name: 'Needs to Sign', img: 'dropdown-pen.svg', active: 'dropdown-active-pen.svg' },
{ name: 'Receives a Copy', img: 'usb-drive.svg', active: 'usb-drive-active.svg' },
// { name: 'In Person Signer', img: 'person.svg', active: 'person-active.svg' },
{ name: 'Needs to View', img: 'eye.svg', active: 'eye-active.svg' }
];
const signmyself_ignored = ['Company', 'Dropdown'];
const selectField = ['Radio', 'Checkbox', 'Dropdown'];

const PDFFields = ({ getSignType, selectColor, activeFields, isOrder, signOrders, showModals, recipientlist,
    handleDelete, onEditHandler, onSelectHandler, handleSignerDelete, closeModal, signerCheckBox,
    handleRecipientHandler, getPasscode, hidden_password, handleAddHandler, handleDeleteHandler,
    changeRecipient, handleChangeField, handleRemovefield, getUserId, approvals, user_id, pulse_id_detail }) => {
    const [showModal, setShowModal] = useState(false);
    const [activeField, setActiveField] = useState({});
    const [date, setDate] = useState([]);
    const [recipient, setRecipient] = useState([{ name: '', id: 1, email: '' }]);
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [checkSign, setCheckSign] = useState(false);
    const [signOrder, setSignOrder] = useState(false);
    const [recipientIndex, setRecipientIndex] = useState(null);
    const [passWordKeys, setPassWordKeys] = useState({});
    const [passCodeErr, setPassCodeErr] = useState({});
    const [toggleRecipient, setToggleRecipient] = useState(false);
    const [searchRecipient, setSearchRecipient] = useState([]);
    const [searchField, setSearchField] = useState(null);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [approval, setApproval] = useState([]);
    const [approver_name, setApprover_name] = useState([]);
    const [approval_open, setApproval_open] = useState(false);
    const [searchApprover, setSearchApprover] = useState([]);
    const [approverErr, setApproverErr] = useState({});
    const [approverDisable, setApproverDisable] = useState(false);

    const crm_param = useSelector((state) => state.crm_param.value);
    const orgId = useSelector((state) => state.document.value);
    const plan = useSelector((state) => state.welcomeImgDetail.value);

    const location = useLocation();
    const dispatch = useDispatch();
    const pathName = location.pathname?.split('/')?.find(v => v === 'edit-template');
    const pathDocument = location.pathname?.split('/')?.find((v) => v === 'document');
    const draftType = window.location.href?.split('&')?.find(s => s === 'action=draft' || s === 'action=approval');
    const sdk_org_id = window?.location?.href?.split('#')[1]?.split('/')[1]?.split('c')[1];

    const signId_details = useSelector((state) => state?.signId_details?.value);
    const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
    const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');
    const disable_signmyself = admin || show_integeration?.user_permission[4].create === 1 || (getSignType === 'Sign myself' && show_integeration?.user_permission[2].create === 1);
    const enable_btn = show_integeration?.user_permission[3]?.create === 1 && show_integeration?.user_permission[4]?.create === 1;

    let select_color = selectColor && !checkSign ? selectColor : '#377dff1a';
    const basicFieldsIcon = [
        <BasicFieldIcons.Name color={select_color} />,
        <BasicFieldIcons.Company color={select_color} />,
        <BasicFieldIcons.Pen color={select_color} />,
        <BasicFieldIcons.Date color={select_color} />,
        <BasicFieldIcons.RadioIcon color={select_color} />,
        <BasicFieldIcons.Initial color={select_color} />,
        <BasicFieldIcons.Fonts color={select_color} />,
        <BasicFieldIcons.CheckboxIcon color={select_color} />,
        <BasicFieldIcons.NumberIcon color={select_color} />,
        <BasicFieldIcons.Title color={select_color} />,
        <BasicFieldIcons.Email color={select_color} />,
        <BasicFieldIcons.Dropdown color={select_color} />,
    ];

    const boardFieldIcon = [
        { name: "status", icon: <BasicFieldIcons.Name color={select_color} /> },
        { name: "board_relation", icon: <BasicFieldIcons.Pen color={select_color} /> },
        { name: "dropdown", icon: <BasicFieldIcons.Title color={select_color} /> },
        { name: "phone", icon: <BasicFieldIcons.NumberIcon color={select_color} /> },
        { name: "email", icon: <BasicFieldIcons.Email color={select_color} /> },
        { name: "mirror", icon: <BasicFieldIcons.Pen color={select_color} /> },
        { name: "long_text", icon: <BasicFieldIcons.Fonts color={select_color} /> },
        { name: "file", icon: <BasicFieldIcons.Dropdown color={select_color} /> },
        { name: "people", icon: <BasicFieldIcons.Name color={select_color} /> },
        { name: "formula", icon: <BasicFieldIcons.Dropdown color={select_color} /> },
        { name: "link", icon: <BasicFieldIcons.Dropdown color={select_color} /> },
        { name: "tags", icon: <BasicFieldIcons.Dropdown color={select_color} /> },
        { name: "timeline", icon: <BasicFieldIcons.Dropdown color={select_color} /> },
        { name: "Item", icon: <BasicFieldIcons.Fonts color={select_color} /> }]

    useEffect(() => {
        setActiveField(activeFields);
    }, [activeFields]);

    const getApprovers = (key) => {
        let data = [];
        if (key === 'user_id') {
            const array = user_id?.map(elem => elem.user_id);
            plan?.organizations_users?.filter((elem, i) => {
                if (array.includes(elem.UserId)) {
                    if (elem?.LastName) data.push({ name: `${elem?.FirstName} ${elem?.LastName}`, background: circle_active_color[i], id: elem?.UserId });
                    else data.push({ name: `${elem?.FirstName}`, background: circle_active_color[i], id: elem?.UserId });
                }
            });
        } else {
            const array = signId_details?.approvers_info?.map(elem => elem.email_Id);
            plan?.organizations_users?.filter((elem, i) => {
                if (array.includes(elem.Email)) {
                    if (elem?.LastName) data.push({ name: `${elem?.FirstName} ${elem?.LastName}`, background: circle_active_color[i], id: elem?.UserId });
                    else data.push({ name: `${elem?.FirstName}`, background: circle_active_color[i], id: elem?.UserId });
                }
            });
        }
        setApproval(data);
        getUserId(user_id);
    };

    useEffect(() => {
        setApprover_name(plan?.organizations_users);
        if (signId_details?.approvers_info?.length) {
            getApprovers('approver_info')
        } else {
            if (!signId_details?.approvers_info?.length && user_id) getApprovers('user_id');
        }
    }, [plan?.organizations_users, user_id]);

    useEffect(() => {
        let signType = getSignType === 'Sign myself';

        if (enable_btn && getSignType === null) {
            setCheckSign(false);
        } else if ((getSignType === null && show_integeration?.user_permission[4]?.create === 1) || (signType && show_integeration?.user_permission[4]?.create === 1)) {
            setCheckSign(true);
        } else {
            setCheckSign(false);
        };

        setSignOrder(isOrder);
        signOrders(isOrder);
    }, [getSignType, isOrder, show_integeration]);

    useEffect(() => {
        setShowModal(showModals);
    }, [showModals]);

    const handleDrag = (e, item, key, idx) => {
        if (key !== 'crm_field') {
            let current = { key: e.target.dataset.value };
            e.dataTransfer.setData('text/plain', JSON.stringify(current));
            e.dataTransfer.effectAllowed = 'move';
        } else {
            let current = { key: e.target.dataset.value, board_fields: key, index: idx };
            e.dataTransfer.setData('text/plain', JSON.stringify(current));
            e.dataTransfer.effectAllowed = 'move';
        }
    };

    const handleDeleteField = (index) => {
        setActiveField({ ...activeField, show: false });
        handleDelete(index);
    };

    const onChangeHandler = (val, index, key, optionIndex, optionValue) => {
        onEditHandler(val, index, key, optionIndex, optionValue);
    };

    const handleNameHandler = (e) => {
        recipient.map((list, i) => {
            if (list.name === '') {
                let name = list?.email?.substring(0, list?.email?.indexOf('@'));
                let data = [...recipient];
                data[i]['name'] = name;
                setRecipient(data);
            }
        });
    };

    const handleSelectHandler = (value, index, key, optionIndex, optionKey) => {
        onSelectHandler(value, index, key, optionIndex, optionKey);
    };

    const handleCheckBox = (e) => {
        const val = e.target.checked;
        if (admin || enable_btn || show_integeration?.user_permission[3]?.create === 1 || show_integeration?.user_permission[2]?.create === 1) {
            setActiveField({ show: false });
            setCheckSign(val);
        };
    };

    const contactDetailScheme = yup.object().shape({
        passCode: yup.string().required('PassCode  is required'),

        contact: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Name  is required'),
                email: yup.string().required('Enter email').email('Enter valid email'),
            })
        ).test('no-repeating-emails', 'Emails must be unique', function (email) {
            const { contact } = this.parent;
            const emailsSet = new Set();
            for (const obj of contact) {
                if (emailsSet.has(obj.email)) {
                    toast.error('Emails must be unique');
                    return false;
                }
                emailsSet.add(obj.email);
            }
            return true;
        }),
    });

    const validatePassCode = (_val) => {
        const val = _val || passWordKeys
        let err = {};

        if (passWordKeys && Object?.keys(passWordKeys).length) {
            Object?.keys(val)?.forEach(key => {
                if (!val[key]) err[key] = 'PassCode  is required'
            })
        }

        setPassCodeErr(err)
        return Object.keys(err).length === 0
    };

    const removeDuplicates = (array) => {
        const duplicates = {};
        const result = [];

        array.forEach(item => {
            if (duplicates[item]) {
                if (!result.includes(item)) {
                    result.push(item);
                }
            } else {
                duplicates[item] = true;
            }
        });

        return result;
    };

    const validateApprover = () => {
        const val = approval;
        let err = {};

        if (approval && approval?.length) {
            val?.forEach((key, i) => {
                if (!val[i].name) err[i] = 'Approver is required'
                else {
                    let validate_user = approver_name.filter(item => `${item?.FirstName} ${item?.LastName}`.includes(val[i].name));
                    let validate_name = null;
                    if (validate_user[0]?.LastName) validate_name = `${validate_user[0]?.FirstName} ${validate_user[0]?.LastName}` === val[i].name;
                    else validate_name = validate_user[0]?.FirstName === val[i].name;
                    let user_ids = approval?.map((id) => id?.id);
                    const uniqueNames = removeDuplicates(user_ids);
                    if (!validate_user?.length || uniqueNames?.length || !validate_name) err[i] = uniqueNames?.length ? 'Duplicate email' : 'Invalid approver';
                }
            })
        }

        setApproverErr(err);
        return Object.keys(err).length === 0
    };

    const contactDetail = useFormik({
        initialValues: {
            contact: [
                {
                    name: '',
                    email: '',
                    id: 1,
                    backgroundColor: circle_active_color[0],
                    background: bgcolor[0],
                    type: 1,
                    order: 1
                },
            ],
            message: [
                {
                    email_sub: '',
                    email_message: '',
                },
            ],

        },
        enableReinitialize: true,
        validationSchema: contactDetailScheme.omit(['passCode']),
        onSubmit: (values) => {
            const isValid = validatePassCode();
            const is_valid = validateApprover();
            if (!isValid) return
            if (!is_valid) return
            const colorArr = [];
            values.contact.forEach((s) => {
                if (s.type === 1) {
                    colorArr.push(s.background)
                }
            })

            let user_ids = [];
            approval?.map((id) => {
                user_ids.push({ user_id: id?.id });
            });

            getUserId(user_ids);
            handleSignerDelete(colorArr);
            handleChangeField(values?.contact);
            setShowModal(false);
            handleRemovefield(false);
            signerCheckBox(checkSign);
            handleRecipientHandler(values?.contact);
            getPasscode(passWordKeys);
        },
    });

    useEffect(() => {
        if (recipientlist?.length) {
            setRecipient(recipientlist);
            contactDetail.setFieldValue('contact',
                recipientlist?.map((o, i) =>
                    o?.order ? o : { ...o, 'order': i + 1 }
                ), false
            );
            let hidden_pass = hidden_password?.password_keys;
            if (hidden_pass) {
                setPassWordKeys(hidden_pass);
                let obj = {}
                hidden_pass && Object.keys(hidden_pass)?.map((key) => {
                    return obj[key] = true
                });
                setToggleSwitch(obj);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipientlist]);

    const contactErr = contactDetail.errors.contact;
    const contactTouched = contactDetail.touched.contact;

    const handleChange = (val, key, idx, background, signer_name) => {
        if (signer?.length === 3 && key === 'type') {
            val = signer_name?.name === 'Needs to View' ? 4 : val;
        };

        if (key === 'name' || key === 'email') {
            setSearchField(key);
        };

        contactDetail.setFieldValue(
            'contact',
            contactDetail.values.contact.map((o, i) =>
                i === idx ? { ...o, [key]: val } : o
            ), false
        );
    };

    const handleSwitch = (idx, e, type) => {
        const checked = e.target.checked;

        if (type === 1 || type === 4) {
            if (!checked && (passWordKeys[idx] || passWordKeys[idx] === null)) {
                let passcode = { ...passWordKeys };
                delete passcode[idx];
                setPassWordKeys(passcode);
            } else {
                setPassWordKeys(pre => ({ ...pre, [idx]: null }));
            }

            setToggleSwitch((pre) => ({ ...pre, [idx]: !toggleSwitch[idx] }));
        }
    };

    const handleSignDelete = (idx, background, id) => {
        contactDetail.setFieldValue(
            'contact',
            contactDetail.values.contact.filter((o, i) => i !== idx), false
        );

        if ((contactDetail?.values?.contact?.length - 1) === 1) {
            setSignOrder(false);
        }

        let passcode = { ...passWordKeys };
        delete passcode[id];
        setPassWordKeys(passcode);
    };

    const handleAddRecipients = () => {
        let id = Math.max(...contactDetail?.values?.contact.map(o => o.id));
        let idx = contactDetail?.values?.contact?.find((s) => s.id === id);
        let bgColor = circle_active_color.indexOf(idx?.backgroundColor);
        let sign_order = Math.max(...contactDetail?.values?.contact.map(o => o.order));
        contactDetail.setFieldValue('contact', [
            ...contactDetail?.values?.contact,
            { name: '', email: '', id: id + 1, backgroundColor: circle_active_color[bgColor + 1], background: bgcolor[bgColor + 1], type: 1, order: sign_order + 1 },
        ], false);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            contactDetail?.values?.contact,
            result.source.index,
            result.destination.index
        );

        if (result.destination.index === 0) {
            contactDetail.setFieldValue(
                'contact',
                items.map((o, i) =>
                    i === result.destination.index ? { ...o, order: 1 } : o
                ), false
            );
        } else if (result.destination.index === items?.length - 1) {
            let data = items[result.destination.index - 1]?.order;
            contactDetail.setFieldValue(
                'contact',
                items.map((o, i) =>
                    i === result.destination.index ? { ...o, order: data + 1 } : o
                ), false
            );
        } else {
            let data = items[result.destination.index - 1]?.order;
            contactDetail.setFieldValue(
                'contact',
                items.map((o, i) =>
                    i === result.destination.index ? { ...o, order: data } : o
                ), false
            );
        }
    };

    const onDragEndApproval = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            approval,
            result.source.index,
            result.destination.index
        );
        setApproval(items);
    };

    const handleClose = () => {
        setShowModal(false);
        closeModal(false);
    };

    const handleSignValue = () => {
        signerCheckBox(checkSign);
        setShowModal(false);
        handleChangeField(null, checkSign);
        handleRemovefield(false);
    };

    const handleSignCheckBox = (e) => {
        const val = e.target.checked;
        setSignOrder(val);
        signOrders(val);
    };

    const handleSignOrder = (val, idx, key) => {
        const regex = /^[0-9\b]+$/;
        if (regex.test(val)) contactDetail.setFieldValue(
            'contact',
            contactDetail.values.contact.map((o, i) =>
                i === idx ? { ...o, [key]: val } : o
            ), false
        );
        setRecipientIndex(idx);
    };

    const handleChangeOrder = () => {
        if (recipientIndex && contactDetail?.values?.contact[recipientIndex]['order'] === 1) {
            let destination_index = 0;
            const items = reorder(contactDetail?.values?.contact, recipientIndex, destination_index);
            contactDetail.setFieldValue('contact', items, false);
        } else if (recipientIndex || recipientIndex === 0) {
            const sort_item = contactDetail?.values?.contact?.sort((a, b) => a.order - b.order);
            contactDetail.setFieldValue('contact', sort_item, false);
        }
        setRecipientIndex(null);
        setSearchField(null);
        setToggleRecipient(false);
    };

    const handlePasscode = (value, id) => {
        setPassWordKeys(pre => {
            const val = { ...pre, [id]: value }

            if (Object.keys(contactDetail.touched).length) validatePassCode(val)
            return val
        });

    };

    const fetchContact = (idx = 0, size = 10, val, index, recipient, key) => {
        axios.get(process.env.REACT_APP_CONTACT, {
            withCredentials: process.env.REACT_APP_CREDENTIALS,
            params: {
                org_id: crm_param ? sdk_org_id : orgId?.OrgId,
                limit: size ?? 10,
                ...(val?.length > 0 ? { search: val } : {}),
            },
        })
            .then((res) => {
                if (recipient[index]['name']?.length !== 0 || recipient[index]['email']?.length !== 0) {
                    setToggleRecipient((pre) => ({ ...pre, [index]: !toggleRecipient[index] }));
                    setSearchRecipient(res?.data?.contacts_list);
                } else if (key) {
                    setSearchField(key);
                    setToggleRecipient((pre) => ({ ...pre, [index]: !toggleRecipient[index] }));
                    setSearchRecipient(res?.data?.contacts_list);
                };
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    const debouncedSendRequest = useMemo(() => debounce(fetchContact, 300), []);

    const handleSearch = (idx = 0, size = 10, val, index, click, key) => {
        if (val?.length !== 0) {
            debouncedSendRequest(idx, size, val, index, contactDetail?.values?.contact);
        } else if (click) {
            if (!contactDetail?.values?.contact[index]['name']?.length && !contactDetail?.values?.contact[index]['email']?.length) fetchContact(idx, size = 5, val, index, contactDetail?.values?.contact, key);
        } else {
            setTimeout(() => {
                setToggleRecipient(false);
                setSearchRecipient([]);
            }, 1000);
        }
    };

    const handleRecipientSearch = (item, index) => {
        setToggleRecipient(false);
        let data = contactDetail?.values?.contact.map((c, idx) => {
            return index === idx ? {
                ...c,
                name: `${item?.First_Name}${item?.Last_Name?.length ? item?.Last_Name : ''}`,
                email: item?.Email_Id,
            } : c
        })
        setSearchRecipient([]);
        contactDetail.setFieldValue('contact', data, false);
        setSelectedIdx(0);
    };

    const handleKeyDown = (event, i) => {
        if (event.key === 'ArrowUp' && selectedIdx > 1) {
            setSelectedIdx((pre) => pre - 1);
        } else if (event.key === 'ArrowDown' && searchRecipient?.length > selectedIdx) {
            setSelectedIdx((pre) => pre + 1);
        } else if (event.key === 'Enter') {
            if (searchRecipient[selectedIdx - 1]) handleRecipientSearch(searchRecipient[selectedIdx - 1], i);
        }
    };

    const handleKeyDownApproval = (e, i) => {
        setApproval_open((pre) => ({ ...pre, [i]: !approval_open[i] }));
        setSearchApprover(approver_name);
    };

    const onChangeOptionHandler = (e) => {
        changeRecipient(e, activeField?.index);
    };

    const handleApproval = () => {
        setApproval([...approval, { name: '', background: circle_active_color[approval?.length] }]);
    };

    const handleApprovalChange = (value, key, i) => {
        let data = [...approval];
        data[i][key] = value;
        setApproval(data);
        if (value) {
            let search_approver = approver_name.filter(item => item?.FirstName?.toLowerCase()?.includes(value?.toLowerCase()));
            if (search_approver?.length) {
                setApproval_open((pre) => ({ ...pre, [i]: !approval_open[i] }));
                setSearchApprover(search_approver);
            }
        } else setApproval_open(false);
    };

    const handleApprovalSelect = (item, i) => {
        let data = [...approval];
        data[i]['name'] = item?.LastName ? `${item?.FirstName} ${item?.LastName}` : item?.FirstName;
        data[i]['id'] = item?.UserId;
        setApproval(data);
        setApproval_open(false);
        if (!approverDisable[i]) setApproverDisable((pre) => ({ ...pre, [i]: !approverDisable[i] }));
    };

    const handleApproverDelete = (idx) => {
        let data = approval.filter((o, i) => i !== idx);
        setApproval(data);
        setApproverDisable((pre) => ({ ...pre, [idx]: !approverDisable[idx] }));
    };

    const handleApprovalClose = () => {
        if (approval_open) {
            setApproval_open(false);
        }
    };

    const handleApproverDisable = (i) => {
        setApproverDisable((pre) => ({ ...pre, [i]: !approverDisable[i] }));
        let data = [...approval];
        data[i]['name'] = '';
        setApproval(data);
    };

    return (
        <div className="field-content">
            <div className="fields-container">
                <div className="modal-mask" style={{ display: showModal ? 'inline-table' : 'none' }}>
                    <div className="modal-wrapper" onBlur={() => handleNameHandler()}>
                        <div className="modal-container" onBlur={() => handleChangeOrder()}>
                            <div className="modal-body" style={{ overflow: recipient.length > 10 ? 'scroll' : 'hidden', height: recipient.length > 10 ? '600px' : 'auto' }}>
                                <div className="recipient" id='recipient-container'>
                                    <div className='recipient-wrapper'>
                                        <div className='recipient-text'>
                                            <div className='recipient-header'>Add Recipient</div>
                                        </div>
                                        <IconButton onClick={() => handleClose()}>
                                            <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                                        </IconButton>
                                    </div>

                                    <Box className='recipient-signer'>
                                        {!checkSign && <Box className={contactDetail?.values?.contact?.length > 1 ? 'select-signer' : 'select-signing-order'}>
                                            <Checkbox
                                                sx={{ transform: 'scale(0.8)', borderColor: 'var(--border-color)' }}
                                                checkedIcon={<CheckedIcon style={{ margin: 2.8 }} />}
                                                checked={signOrder}
                                                onClick={(e) => handleSignCheckBox(e)}
                                                disabled={contactDetail?.values?.contact?.length > 1 ? false : true}
                                            />
                                            <div className='signer-wrapper'>Set a signing order</div>
                                            <Tooltip placement='top' title=''>
                                                <img
                                                    src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/signer-tooltip.svg'}
                                                    className='signer-img'
                                                    alt='menu-item'
                                                />
                                            </Tooltip>
                                        </Box>}
                                        {(!crm_param && !draftType && pathName !== 'edit-template' && disable_signmyself) && !approval?.length &&
                                            <Box className='select-signer'>
                                                <Checkbox
                                                    disabled={approvals}
                                                    checked={checkSign}
                                                    checkedIcon={<CheckedIcon style={{ margin: 2 }} />}
                                                    sx={{ transform: 'scale(0.8)', borderColor: 'var(--border-color)' }}
                                                    onClick={(e) => handleCheckBox(e)}
                                                />
                                                <div className='signer-wrapper'>I'm the only signer</div>
                                                <Tooltip placement='top' title='Only signer'>
                                                    <img
                                                        src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/signer-tooltip.svg'}
                                                        className='signer-img'
                                                        alt='menu-item'
                                                    />
                                                </Tooltip>
                                            </Box>
                                        }

                                        {/* {(admin || show_integeration?.user_permission[3].create === 1) && <Box className='select-signer'>
                                            <Checkbox
                                                checked={true}
                                                checkedIcon={<CheckedIcon style={{ margin: 2 }} />}
                                                sx={{ transform: 'scale(0.8)', borderColor: 'var(--border-color)' }}
                                                onClick={(e) => handleCheckBox(e)}
                                            />
                                            <div className='signer-wrapper'>I'm the only signer</div>
                                            <Tooltip placement='top' title='Only signer'>
                                                <img
                                                    src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/signer-tooltip.svg'}
                                                    className='signer-img'
                                                    alt='menu-item'
                                                />
                                            </Tooltip>
                                        </Box>} */}
                                    </Box>
                                    <Box className='recipient-body' onBlur={() => handleApprovalClose()} sx={{ height: (contactDetail?.values?.contact?.length >= 3 && !checkSign) || (Object?.keys(toggleSwitch)?.length >= 1 && contactDetail?.values?.contact?.length >= 2) || (approval?.length >= 3 && contactDetail?.values?.contact?.length >= 1) ? 'calc(100vh - 250px)' : '100%' }}>
                                        <DragDropContext onDragEnd={onDragEndApproval}>
                                            <Droppable direction='vertical' droppableId="droppable">
                                                {(provided) => (
                                                    <Box {...provided.droppableProps} ref={provided.innerRef}
                                                    >
                                                        {provided.placeholder}
                                                        {approval?.map((approvel, i) => {
                                                            return (
                                                                <Draggable direction='vertical' Draggable key={i} draggableId={`draggable${i}`} index={i}>
                                                                    {(provided) => (
                                                                        <Box key={i} id={i} style={{ position: 'relative' }} ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps} onClick={() => handleApprovalClose()}>
                                                                            {provided.placeholder}
                                                                            <Box className='recipient-input-container' key={i}>
                                                                                <Box
                                                                                    className='color_container'
                                                                                    style={{ backgroundColor: approvel?.background }}
                                                                                ></Box>
                                                                                <Box
                                                                                    className='add_recepient_container'
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        padding:
                                                                                            approval?.length > 1
                                                                                                ? '15px'
                                                                                                : '15px 15px 15px 50px',
                                                                                    }}
                                                                                >
                                                                                    {approval?.length > 1 && (
                                                                                        <Box className='drag_icon'>
                                                                                            <DragIndicatorIcon />
                                                                                        </Box>
                                                                                    )}
                                                                                    <Box className='input_box_container'>
                                                                                        <Box style={{ position: 'relative' }}>
                                                                                            <InputLabel className='add_recepient_label'>
                                                                                                Name <span style={{ color: 'red' }}>*</span>
                                                                                            </InputLabel>
                                                                                            <Box className='approver_text_field'>
                                                                                                <TextField
                                                                                                    onChange={(e) => !approverDisable[i] && handleApprovalChange(e.target.value, 'name', i)}
                                                                                                    onClick={(e) => handleKeyDownApproval(e, i)}
                                                                                                    onKeyDown={() => setApproval_open(false)}
                                                                                                    value={approvel?.name}
                                                                                                    disabled={(approvals && !pathName)}
                                                                                                    error={!!approverErr[i]}
                                                                                                    helperText={approverErr[i]}
                                                                                                    sx={{
                                                                                                        fieldset: { border: "1px solid #E6E7E9" },
                                                                                                        '& .MuiOutlinedInput-root': {
                                                                                                            '&:hover fieldset': {
                                                                                                                borderColor: 'var(--primary)',
                                                                                                            },
                                                                                                            fontSize: '14px',
                                                                                                            paddingLeft: '2px'
                                                                                                        },
                                                                                                        width: '100%',
                                                                                                        position: 'relative'
                                                                                                    }}
                                                                                                    variant='outlined'
                                                                                                    placeholder="Search approver"
                                                                                                    className='recipient-input'
                                                                                                />
                                                                                                {approvel?.name && <CloseIcon className='approver_close' sx={{ marginBottom: approverErr[i] ? '20px !important' : '0px' }} onClick={() => handleApproverDisable(i)} fontSize="small" />}
                                                                                            </Box>
                                                                                            {approval_open[i] &&
                                                                                                <Box className={i > 1 ? 'field_modal_result' : 'field_modal_result_drop'} sx={{ position: 'absolute' }}>
                                                                                                    {searchApprover?.map((item, index) => {
                                                                                                        return (
                                                                                                            <Box key={index} className='field_keyDown_list' onClick={() => handleApprovalSelect(item, i)}>
                                                                                                                <Box>{item?.FirstName}{item?.LastName?.length ? item?.LastName : ''} ({item?.RoleName})</Box>
                                                                                                                <Box>{item?.Email}</Box>
                                                                                                            </Box>
                                                                                                        )
                                                                                                    })}
                                                                                                </Box>
                                                                                            }
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Select open={false} MenuProps={{ classes: { paper: 'recipient-select-container' } }} sx={{
                                                                                        '.MuiOutlinedInput-notchedOutline': { border: 'none' }, "&:hover": {
                                                                                            "&& fieldset": {
                                                                                                borderColor: "var(--primary)"
                                                                                            }
                                                                                        },
                                                                                        color: 'var(--primary)', fontFamily: 'var(--primary-font-family)', border: '1px solid var(--border-color)'
                                                                                    }} className='signSelect' onChange={(e) => { }}
                                                                                        defaultValue="Approver"
                                                                                        inputProps={{ IconComponent: () => null }}
                                                                                        renderValue={(val) => <Box sx={{ fontSize: '13px', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                                                            <Box style={{ marginLeft: '7px' }}>Approver</Box>
                                                                                        </Box>}
                                                                                    >
                                                                                        <MenuItem value='Approver' className='need-to-view' key={i}>
                                                                                            Approver
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                    <Box sx={{ width: '8% !important' }}>
                                                                                        <Delete
                                                                                            className='recipient_delete'
                                                                                            sx={{ "&:hover": { color: "#ed4c78" }, display: 'flex' }}
                                                                                            onClick={() => (!approvals || (approvals && pathName)) && handleApproverDelete(i)}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>)}
                                                                </Draggable>)
                                                        })}
                                                    </Box>)}
                                            </Droppable>
                                        </DragDropContext>
                                        {approval?.length ? <Divider className='divider_wrapper' /> : null}
                                        {!checkSign && <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable direction='vertical' droppableId="droppable">
                                                {(provided) => (
                                                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                                                        {provided.placeholder}
                                                        {contactDetail?.values?.contact?.map((item, i) => {
                                                            const error = contactErr?.[i];
                                                            const touched = contactTouched?.[i];
                                                            return (
                                                                <Draggable direction='vertical' Draggable key={item?.id} draggableId={`draggable${i}`} index={i}>
                                                                    {(provided) => (
                                                                        <Box key={i} id={i} style={{ position: 'relative' }} ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps} onClick={() => handleChangeOrder()}>
                                                                            {provided.placeholder}
                                                                            <Box className='recipient-input-container'>
                                                                                <Box className='recipient_sign_order'>
                                                                                    {(signOrder === true && contactDetail?.values?.contact?.length > 1) &&
                                                                                        <Box className='recipient_index'>
                                                                                            <TextField
                                                                                                className='sign_order_input'
                                                                                                onChange={(e) => handleSignOrder((Number(e.target.value)), i, 'order')}
                                                                                                inputProps={{ maxLength: 2, style: { textAlign: 'center', fontSize: '12px' } }}
                                                                                                value={item?.order}
                                                                                                type='tel'
                                                                                                disabled={approvals && !pathName}
                                                                                            />
                                                                                        </Box>
                                                                                    }
                                                                                </Box>
                                                                                <Box
                                                                                    className='color_container'
                                                                                    style={{ backgroundColor: item?.backgroundColor }}
                                                                                ></Box>
                                                                                <Box
                                                                                    className='add_recepient_container'
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        padding:
                                                                                            contactDetail.values.contact.length > 1
                                                                                                ? '15px'
                                                                                                : '15px 15px 15px 50px',
                                                                                    }}
                                                                                >
                                                                                    {contactDetail.values.contact.length > 1 && (
                                                                                        <Box className='drag_icon'>
                                                                                            <DragIndicatorIcon />
                                                                                        </Box>
                                                                                    )}
                                                                                    <Box className='input_box_container'>
                                                                                        <Box style={{ position: 'relative' }}>
                                                                                            <InputLabel className='add_recepient_label'>
                                                                                                Name <span style={{ color: 'red' }}>*</span>
                                                                                            </InputLabel>
                                                                                            <TextField
                                                                                                onChange={(e) => [handleChange(e.target.value, 'name', i), ((admin || show_integeration?.user_permission[5]?.read === 1) && handleSearch(0, 10, e.target.value, i))]}
                                                                                                onKeyDown={(e) => handleKeyDown(e, i)}
                                                                                                onClick={() => handleSearch(0, 10, '', i, 'click', 'name')}
                                                                                                value={item.name}
                                                                                                error={!!touched?.name && error?.name ? true : false}
                                                                                                helperText={touched?.name && error?.name}
                                                                                                sx={{
                                                                                                    fieldset: { border: "1px solid #E6E7E9" },
                                                                                                    '& .MuiOutlinedInput-root': {
                                                                                                        '&:hover fieldset': {
                                                                                                            borderColor: 'var(--primary)',
                                                                                                        },
                                                                                                        fontSize: '14px',
                                                                                                        paddingLeft: '2px'
                                                                                                    },
                                                                                                }}
                                                                                                name='name'
                                                                                                variant='outlined'
                                                                                                placeholder="Your name"
                                                                                                className='recipient-input'
                                                                                                disabled={approvals && !pathName}
                                                                                            />
                                                                                            {toggleRecipient[i] && searchField === 'name' &&
                                                                                                <Box className={i > 1 ? 'field_modal_result' : 'field_modal_result_drop'} sx={{ position: 'absolute' }}>
                                                                                                    {searchRecipient?.map((item, index) => {
                                                                                                        return (
                                                                                                            <Box key={index} className={(selectedIdx - 1) === index ? 'field_keyDown_list' : 'field_dropDown_list'} onClick={() => handleRecipientSearch(item, i)}>
                                                                                                                <Box>{item?.First_Name}{item?.Last_Name?.length ? item?.Last_Name : ''}</Box>
                                                                                                                <Box>{item?.Email_Id}</Box>
                                                                                                            </Box>
                                                                                                        )
                                                                                                    })}
                                                                                                </Box>
                                                                                            }
                                                                                        </Box>
                                                                                        <Box style={{ marginTop: '7px', position: 'relative' }}>
                                                                                            <InputLabel className='add_recepient_label'>
                                                                                                Email <span style={{ color: 'red' }}>* </span>
                                                                                            </InputLabel>
                                                                                            <TextField
                                                                                                onChange={(e) => [handleChange(e.target.value, 'email', i), ((admin || show_integeration?.user_permission[5]?.read === 1) && handleSearch(0, 10, e.target.value, i))]}
                                                                                                onKeyDown={(e) => handleKeyDown(e, i)}
                                                                                                onClick={() => handleSearch(0, 10, '', i, 'click', 'email')}
                                                                                                value={item.email}
                                                                                                error={!!touched?.email && error?.email ? true : false}
                                                                                                helperText={touched?.email && error?.email}
                                                                                                sx={{
                                                                                                    fieldset: { border: "1px solid #E6E7E9" },
                                                                                                    '& .MuiOutlinedInput-root': {
                                                                                                        '&:hover fieldset': {
                                                                                                            borderColor: 'var(--primary)',
                                                                                                        },
                                                                                                        fontSize: '14px',
                                                                                                        paddingLeft: '2px'
                                                                                                    },
                                                                                                }}
                                                                                                placeholder="Your email"
                                                                                                name='email'
                                                                                                variant='outlined'
                                                                                                className='recipient-input'
                                                                                                disabled={approvals && !pathName}
                                                                                            />
                                                                                            {toggleRecipient[i] && searchField === 'email' &&
                                                                                                <Box className={i !== 0 ? 'field_modal_result' : 'field_modal_result_drop'} sx={{
                                                                                                    position: 'absolute'
                                                                                                }}>
                                                                                                    {searchRecipient?.map((item, index) => {
                                                                                                        return (
                                                                                                            <Box key={index} className={(selectedIdx - 1) === index ? 'field_keyDown_list' : 'field_dropDown_list'} onClick={() => handleRecipientSearch(item, i)}>
                                                                                                                <Box>{item?.First_Name}{item?.Last_Name?.length ? item?.Last_Name : ''}</Box>
                                                                                                                <Box>{item?.Email_Id}</Box>
                                                                                                            </Box>
                                                                                                        )
                                                                                                    })}
                                                                                                </Box>}
                                                                                        </Box>
                                                                                        <Box className='recipient-verification'>
                                                                                            Recipient verification
                                                                                            <HelpOutlineIcon style={{ color: 'var(--table-header-font)' }} />
                                                                                            <Switch
                                                                                                checked={toggleSwitch[item?.id] ?? false}
                                                                                                disabled={approvals && !pathName}
                                                                                                onChange={(e) => handleSwitch(item?.id, e, item?.type)}
                                                                                                color="default"
                                                                                                sx={{
                                                                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                                                                        color: 'var(--primary)',
                                                                                                    },
                                                                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                                                                        backgroundColor: 'var(--primary)',
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                        {toggleSwitch[item?.id] === true && (
                                                                                            <Box className='recipient-passcode-body'>
                                                                                                <Box className='recipient_passcode' sx={{ width: '40%' }}>
                                                                                                    Verification Type
                                                                                                    <Box sx={{ width: '100%', textAlign: 'initial' }}>
                                                                                                        <Select sx={{
                                                                                                            '.MuiOutlinedInput-notchedOutline': { border: 'none' }, "&:hover": {
                                                                                                                "&& fieldset": {
                                                                                                                    borderColor: "var(--primary)"
                                                                                                                }
                                                                                                            },
                                                                                                            color: 'var(--table-font-color)', fontFamily: 'var(--primary-font-family)', border: '1px solid var(--border-color)'
                                                                                                        }}
                                                                                                            className='passcode'
                                                                                                            value={'Passcode'} onChange={(e) => { }}>
                                                                                                            <MenuItem className='menu-item' value='Passcode'>Passcode</MenuItem>
                                                                                                            {/* <MenuItem className='menu-item' value='Passcode 1'>Passcode 1</MenuItem> */}
                                                                                                        </Select>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                                <Box className='recipient_passcode' sx={{ width: '60%' }}>
                                                                                                    {/* Verification Message */}
                                                                                                    Passcode
                                                                                                    <Box sx={{ width: '100%', marginTop: '15px', '.MuiOutlinedInput-notchedOutline': { border: 'none' }, }}>
                                                                                                        <TextField
                                                                                                            onChange={(e) => handlePasscode(e.target.value, item?.id, i)}
                                                                                                            value={passWordKeys[item?.id] ?? ''}
                                                                                                            error={!!passCodeErr[item.id]}
                                                                                                            helperText={passCodeErr[item.id]}
                                                                                                            sx={{
                                                                                                                fieldset: { border: "1px solid var(--border-color) !important" },
                                                                                                                '& .MuiOutlinedInput-root': {
                                                                                                                    '&:hover fieldset': {
                                                                                                                        borderColor: 'var(--primary)',
                                                                                                                    },
                                                                                                                    fontSize: '14px',
                                                                                                                    paddingLeft: '2px'
                                                                                                                },
                                                                                                            }}
                                                                                                            name='passcode'
                                                                                                            variant='outlined'
                                                                                                            placeholder="Your passcode"
                                                                                                            className='verification-input'
                                                                                                            inputProps={{ maxLength: 60 }}
                                                                                                        />
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                    </Box>
                                                                                    <Select MenuProps={{ classes: { paper: 'recipient-select-container' } }} sx={{
                                                                                        '.MuiOutlinedInput-notchedOutline': { border: 'none' }, "&:hover": {
                                                                                            "&& fieldset": {
                                                                                                borderColor: "var(--primary)"
                                                                                            }
                                                                                        },
                                                                                        color: 'var(--primary)', fontFamily: 'var(--primary-font-family)', border: '1px solid var(--border-color)'
                                                                                    }}
                                                                                        value={item?.type === 4 ? signer[item?.type - 2] : signer[item?.type - 1]} className='signSelect' onChange={(e) => handleChange(signer.indexOf(e.target.value) + 1, 'type', i, item?.background, e.target.value)}
                                                                                        renderValue={(val) => <Box sx={{ fontSize: '13px', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                                                            <img
                                                                                                src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/' + val?.active}
                                                                                                className='signer-active-img'
                                                                                                alt={item}
                                                                                            />
                                                                                            <Box style={{ marginLeft: '7px' }}>{val?.name}</Box>
                                                                                        </Box>}
                                                                                        disabled={approvals && !pathName}
                                                                                    >
                                                                                        {signer?.map((list, i) => (
                                                                                            <MenuItem className='need-to-view' value={list} key={i}>
                                                                                                <img
                                                                                                    src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/' + list.img}
                                                                                                    className='signer-select-img'
                                                                                                    alt={item}
                                                                                                />
                                                                                                {list.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                    <Box sx={{ width: '8% !important' }}>
                                                                                        <Delete
                                                                                            disabled={approvals && !pathName}
                                                                                            className='recipient_delete'
                                                                                            sx={{ "&:hover": { color: "#ed4c78" } }}
                                                                                            onClick={() => (!approvals || (approvals && pathName)) && handleSignDelete(i, item?.background, item?.id)}
                                                                                            style={{
                                                                                                display:
                                                                                                    contactDetail.values.contact.length > 1 ? 'flex' : 'none',
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                    }
                                                                </Draggable>
                                                            );
                                                        })}
                                                    </Box>
                                                )}
                                            </Droppable>
                                        </DragDropContext>}
                                    </Box>
                                </div>
                                {/* || !JSON.parse(plan?.plans_info)[0]?.plan_flags?.SIGN_MULTIPLE_USER */}
                                {(!approvals || (approval && pathName)) && <Box className={checkSign ? 'sign-bottom-container' : 'bottom-container'}>
                                    <Box>
                                        {/* JSON.parse(plan?.plans_info)[0]?.plan_flags?.SIGN_MULTIPLE_USER && */}
                                        {!checkSign &&
                                            <Button className='recipients-add' onClick={() => handleAddRecipients()} disabled={approvals && !pathName}>
                                                <img
                                                    src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/person-add.svg'}
                                                    className='recipient-add-img'
                                                    alt='recipient-add'
                                                />
                                                <Box className='recipient-add-text'>Add Recipients</Box>
                                            </Button>
                                        }
                                        {/* show_integeration?.plan_details?.length && JSON.parse(show_integeration?.plan_details)?.plan_name !== 'Free' && */}
                                        {!checkSign && (!pathName || (pathName && approval)) &&
                                            <Button className='recipients-add' onClick={handleApproval} disabled={approvals && !pathName}>
                                                <img
                                                    src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/person-add.svg'}
                                                    className='recipient-add-img'
                                                    alt='recipient-add'
                                                />
                                                <Box className='recipient-add-text'>Add Approver</Box>
                                            </Button>}
                                    </Box>
                                    <Box className='button-container' style={{ marginTop: checkSign ? '30px' : '0px' }}>
                                        <Button variant='contained' className="modal-button" type="button" onClick={() => {
                                            (checkSign ? handleSignValue : contactDetail.handleSubmit)()
                                            validatePassCode()
                                            validateApprover()
                                        }}>
                                            Proceed
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/proceed-icon.svg'}
                                                className='recipient-proceed-img'
                                                alt='recipient-add'
                                            />
                                        </Button>
                                    </Box>
                                </Box>}
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pdf-drag-field">
                    <div className="pdf-fields-wrapper">
                        {!activeField?.show ?
                            (!approvals || (approvals && pathName)) &&
                            <>
                                <Box className="pdf-fheader">Basic Fields</Box>
                                <Box className="basic-field-container">
                                    {basicFields.map((item, index) => {
                                        let disable_type = contactDetail?.values?.contact?.find((s) => s.type === 1);
                                        let data = checkSign ? signmyself_ignored.indexOf(item) === -1 && item : item;
                                        return (
                                            data && <Box className={disable_type || checkSign ? "pdf-field-propertise" : 'pdf-field-propertise-disabled'} data-value={item} key={index} onDragStart={(e) => handleDrag(e, item)} draggable={disable_type || checkSign ? true : false}>
                                                <Box className='basic-fields-img'>
                                                    {/* <img
                                                        src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/' + basicFieldsIcon[index]}
                                                        className='select-img'
                                                        draggable={false}
                                                        alt={item}
                                                    /> */}
                                                    {basicFieldsIcon[index]}
                                                </Box>
                                                <span className='pdf-field-propertise-text'>{item}</span>
                                            </Box>
                                        )
                                    })}
                                </Box>
                                {pulse_id_detail?.length ? <Box className="crm_fheader">board Fields</Box> : null}
                                {pulse_id_detail?.length ? <Box className="basic-field-container">
                                    {pulse_id_detail?.map((item, index) => {
                                        let board_icon_filter = boardFieldIcon.find(f => f.name === item?.type)
                                        return (
                                            <Box className="pdf-field-propertise" data-value={item?.display_name} key={index} onDragStart={(e) => handleDrag(e, item, 'crm_field', index)} draggable={true}>
                                                <Box className='board_fields_img'>
                                                    {board_icon_filter?.icon}
                                                </Box>
                                                <span className='pdf-field-propertise-text'>{item?.display_name}</span>
                                            </Box>
                                        )
                                    })}
                                </Box> : null}

                                {/*<div className="pdf-fheader">Custom Fields</div>
                                 <div className='custom-field-container'>
                                    <div className='custom-field-body'>
                                        <div className='custom-field-text'>Add a custom field</div>
                                        <img
                                            src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/menu-items.svg'}
                                            className='menu-item-img'
                                            alt='menu-item'
                                        />
                                        <img
                                            src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/plus.svg'}
                                            className='plus-img'
                                            alt='menu-item'
                                        />
                                    </div>
                                </div> */}
                            </> :
                            <div>
                                <div className="pdf-fheader-field">Field Properties<div className="close-icon" onClick={() => setActiveField({ ...activeField, show: false })}><CloseIcon sx={{ width: '20px', height: '20px' }} /></div></div>
                                <div className="field-container">
                                    <div className="required-field">
                                        {checkSign ? null :
                                            <>
                                                <Checkbox disableRipple
                                                    checked={activeField.is_required}
                                                    icon={<Box sx={{ width: '15px', height: '15px', border: '1px solid var(--border-color)', borderRadius: '5px' }}></Box>}
                                                    checkedIcon={<CheckedIcon style={{ width: '15px', height: '15px' }} />}
                                                    onChange={(e) => onChangeHandler(e.target.checked, activeField?.index, 'is_required')}
                                                    sx={{
                                                        color: 'var(--primary)',
                                                        '&.Mui-checked': {
                                                            color: 'var(--primary)',
                                                        },
                                                        border: 'none',
                                                    }}
                                                    disabled={(approvals && !pathName) || activeField?.board_details ? true : false}
                                                />
                                                <span>Required</span><br />

                                                {/* {activeField?.type === 12 && <>
                                                    <input type="checkbox" value={activeField.is_read_only} className="checkbox"
                                                        onClick={(e) => onChangeHandler(!activeField.is_read_only, activeField?.index, 'is_read_only')} />
                                                    <span>Read-Only</span>
                                                </>} */}
                                            </>}

                                        {!checkSign && contactDetail?.values?.contact[0]?.email &&
                                            <>
                                                <Box className="fheader">Recipient</Box>
                                                <Select
                                                    disabled={approvals && !pathName}
                                                    sx={{ '.MuiOutlinedInput-notchedOutline': { border: 'none !important' } }}
                                                    className='recipient_select_dropdown'
                                                    MenuProps={{ classes: { paper: 'recipient_select', border: 'none' } }}
                                                    value={contactDetail?.values?.contact[activeField?.key - 1]?.email}
                                                    onChange={() => { }}
                                                >
                                                    {contactDetail?.values?.contact?.map((list, idx) => {
                                                        return (
                                                            list?.type === 1 &&
                                                            <MenuItem key={idx} onClick={() => onChangeOptionHandler(list)} value={list.email} className="select-field-body">
                                                                <span>{list.email}</span>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </>
                                        }
                                        {(activeField?.type === 12 && !checkSign) && <>
                                            <div className="fheader">Field label</div>
                                            <input type="text"
                                                value={activeField?.initial_value}
                                                disabled={approvals && !pathName}
                                                onChange={(e) => onChangeHandler(e.target.value, activeField?.index, 'initial_value')}
                                                className="field-input"
                                            />
                                        </>}
                                        <div className="fheader">{checkSign ? 'Field Value' : activeField?.type === 12 ? 'Initial Value' : 'Field label'}</div>
                                        <input
                                            type={checkSign && activeField?.type === 13 ? 'number' : 'text'}
                                            value={activeField?.value}
                                            disabled={approvals && !pathName}
                                            pattern="[0-9]*"
                                            onKeyDown={(evt) => checkSign && activeField?.type === 13 && evt.key === 'e' && evt.preventDefault()}
                                            onChange={(e) => checkSign && activeField?.type === 7 ? {} : activeField?.board_details !== 'board_detail' && onChangeHandler(e.target.value, activeField?.index, 'value')}
                                            className="field-input"
                                        />
                                        <div className="dimension-row" style={{ display: activeField?.field_value?.replace(/\s|[0-9]/g, '') === 'Radio' || activeField?.field_value?.replace(/\s|[0-9]/g, '') === 'Checkbox' ? 'none' : 'flex' }}>
                                            {!checkSign && <div className="width-container">
                                                <div className="width-fheader">Width (px)</div>
                                                <input
                                                    type="number"
                                                    disabled={approvals && !pathName}
                                                    onChange={(e) => onChangeHandler(Number(e.target.value), activeField?.index, 'width')}
                                                    value={activeField?.width}
                                                    className="field-width"
                                                />
                                            </div>}
                                            <div className="width-container" style={{ marginLeft: '10px' }}>
                                                {(activeField?.type === 12 || activeField?.type === 9) &&
                                                    <>
                                                        <div className="width-fheader">Height (px)</div>
                                                        <input
                                                            type="number"
                                                            disabled={approvals && !pathName}
                                                            onChange={(e) => onChangeHandler(e.target.value, activeField?.index, 'height')}
                                                            value={activeField?.height}
                                                            className="field-width"
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <PDFSelectedList
                                            activeField={activeField}
                                            onChangeHandler={(e, activeIndex, key, optionIndex, optionValue) => onChangeHandler(e, activeIndex, key, optionIndex, optionValue)}
                                            date={date}
                                            fieldList={activeFields?.options}
                                            handleAddHandler={handleAddHandler}
                                            handleDeleteHandler={handleDeleteHandler}
                                            onSelectHandler={(value, index, key, optionKey) => handleSelectHandler(value, index, key, optionKey)}
                                        />
                                        <div className="delete-container" style={{ marginTop: '12px' }}>
                                            <div disabled={approvals && !pathName} className="delete-field" onClick={() => (!approvals || (approvals && pathName)) && handleDeleteField(activeField?.index)}>Delete Field</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
};

export default PDFFields;