import React from "react";
import Checkbox from "react-custom-checkbox";

const dateFormat = [
    '31/01/2001',
    '01/31/2001',
    '2001/01/31',
    '31/JAN/2001',
    'JAN/31/2001',
]

const PDFSelectedList = (props) => {

    let { activeField, onChangeHandler, onSelectHandler, fieldList } = props;

    switch (activeField?.field_value?.replace(/\s|[0-9]/g, '')) {
        case 'Date':
            return (
                <>
                    <div className="fheader">Date Format</div>
                    <select
                        value={activeField?.data_format}
                        onChange={(e) => onChangeHandler(dateFormat.indexOf(e.target.value) + 1, activeField?.index, 'date_format')}
                        className="field-input"
                    >
                        {dateFormat?.map((item, i) => (
                            <option key={i}>{item}</option>
                        ))}
                    </select>
                </>)
        case 'Radio':
            return (
                <div>
                    <div className="fheader-radio">Radio Button Options</div>
                    {fieldList?.map((item, index) => {
                        return <div key={index} className='list-input-field'>
                            <div className="radio">
                                <input className="radio-select" style={{ opacity: item.is_selected ? '1' : '0' }} value={item.is_selected}
                                    onClick={(e) => onSelectHandler(item.is_selected, activeField?.index, item.id, 'RadioButton')} onChange={() => { }}
                                />
                            </div>
                            <input
                                type="text"
                                value={item?.value}
                                className='field-select-input'
                                style={{ border: index === activeField.optionIndex ? '1px solid var(--primary)' : '1px solid #e0d7ff' }}
                                onChange={(e) => onChangeHandler(e.target.value, activeField?.index, 'options', index, 'value')}
                            />
                            <div className="field-add-delete" onClick={() => props?.handleDeleteHandler('options', activeField?.index, index)}>-</div>
                            {fieldList?.length === index + 1 && <div className="field-add-delete" onClick={() => props?.handleAddHandler('options', 'Radiobutton', activeField?.index, item)}>+</div>}
                        </div>
                    })}
                </div>)

        case 'Checkbox':
            return (
                <div>
                    <div className="fheader-radio">Checkbox Value</div>
                    {fieldList?.map((item, index) => {
                        return <div key={index} className='list-input-field'>
                            <Checkbox
                                icon={<div className="custom-icon"><div className="icon-before-select" /> <div className="icon-after-select" /></div>}
                                name={item.value}
                                value={item.value}
                                checked={item.is_selected}
                                onChange={(value, event) => {
                                    onSelectHandler(value, activeField?.index, item.id, 'Checkbox')
                                }}
                                style={{ border: '1px solid #e0d7ff', width: '20px', height: '20px' }}
                                className="checkbox-custom-select"
                            />
                            <input
                                type="text"
                                value={item?.value}
                                className='field-select-input'
                                onChange={(e) => onChangeHandler(e.target.value, activeField?.index, 'options', index, 'value')}
                            />
                            <div className="field-add-delete" onClick={() => props?.handleDeleteHandler('options', activeField?.index, index)}>-</div>
                            {fieldList?.length === index + 1 && <div className="field-add-delete" onClick={() => props?.handleAddHandler('options', 'Checkbox', activeField?.index, item)}>+</div>}
                        </div>
                    })}
                </div>)

        case 'Dropdown':
            return (
                <div>
                    <div className="fheader-radio">Dropdown Options</div>
                    {fieldList?.map((item, index) => {
                        return <div key={index} className='list-input-field'>
                            <input
                                type="text"
                                value={item?.value}
                                className='field-select-input'
                                onChange={(e) => onChangeHandler(e.target.value, activeField?.index, 'options', index, 'value')}
                            />
                            <div className="field-add-delete" onClick={() => props?.handleDeleteHandler('options', activeField?.index, index)}>-</div>
                            {fieldList?.length === index + 1 && <div className="field-add-delete" onClick={() => props?.handleAddHandler('options', 'Dropdown', activeField?.index, item)}>+</div>}
                        </div>
                    })}
                </div>)
        default:
            break;
    }
};

export default PDFSelectedList;